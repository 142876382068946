import React, {useEffect, useState} from "react";
import { Grid } from 'semantic-ui-react'

export const Class = ({class_val}) => {
    const [students, setStudents] = useState([])
    useEffect(()=>{
        if(class_val.Students !== null){
            setStudents(class_val.Students.split(','))
        }
        
    },[class_val])
    return (

        <Grid.Row>
            <Grid.Column computer={12} className='sectionTitle' textAlign='center' data-aos="slide-left">
                <h3>{class_val.Class_Name}</h3>
            </Grid.Column>
            <Grid.Column computer={12} textAlign='center' data-aos="slide-right">
                <p>
                {students.map((val)=>{
                            return val + ', '
                        })}
                </p>
            </Grid.Column>
        </Grid.Row>

    )
}

export default Class;