import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BASE_URL } from "../../../const";

export const SupervisingTeachers = ({ itinerary }) => {
  const [teacherImage1, setTeacherImage1] = useState("");
  const [teacherImage2, setTeacherImage2] = useState("");
  const [teachers, setTeachers] = useState([]);
  useEffect(() => {
    console.log(itinerary);
    if (itinerary.Teachers_Image_1.data !== null) {
      if (
        itinerary.Teachers_Image_1.data.attributes.formats.hasOwnProperty(
          "small"
        )
      ) {
        let file =
          `${BASE_URL}` +
          itinerary.Teachers_Image_1.data.attributes.formats.small.url;
        setTeacherImage1(file);
      } else {
        let file =
          `${BASE_URL}` + itinerary.Teachers_Image_1.data.attributes.url;
        setTeacherImage1(file);
      }
    }
    if (itinerary.Teachers_Image_2.data !== null) {
      if (
        itinerary.Teachers_Image_2.data.attributes.formats.hasOwnProperty(
          "small"
        )
      ) {
        let file =
          `${BASE_URL}` +
          itinerary.Teachers_Image_2.data.attributes.formats.small.url;
        setTeacherImage2(file);
      } else {
        let file =
          `${BASE_URL}` + itinerary.Teachers_Image_2.data.attributes.url;
        setTeacherImage2(file);
      }
    }
    if (itinerary.Teachers_Names !== null) {
      const teachers = itinerary.Teachers_Names.split(",");
      setTeachers(teachers);
    }
  }, [itinerary]);

  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);

  return (
    <Grid.Row data-aos="fade-left" id="SUPERVISING_TEACHERS" className="iti_section">
      <Grid centered>
        <Grid.Column
          computer={6}
          mobile={6}
          tablet={12}
          textAlign="center"
          className="specialColumn"
          data-aos="fade-left"
        >
          <div className="supervisingTeachersImgContainer">
            <div
              className="supervisingTeachersImgBox1"
              style={{
                backgroundImage: `url("${teacherImage1}")`,
                objectFit: "cover",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="supervisingTeachersImgBox"
              style={{
                backgroundImage: `url("${teacherImage2}")`,
                objectFit: "cover",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={12}
          tablet={12}
          verticalAlign="middle"
        >
          <Grid.Column computer={10} tablet={12} mobile={12} textAlign="center">
            <h2>supervising teacher/s</h2>
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={12}
            mobile={12}
            className="supervisingTeachersList"
            data-aos="fade-right"
          >
            {teachers.map((val) => {
              return val + ", ";
            })}
          </Grid.Column>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  );
};

export default SupervisingTeachers;
