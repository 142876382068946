import React, { useEffect, useState } from "react";
import { Grid, Loader, Button, Icon } from "semantic-ui-react";
import Routes from "./Routes";
import ClassStudents from "./ClassStudents";
import SupervisingTeachers from "./SupervisingTeachers";
import Friendships from "./Friendships";
import TripMap from "./TripMap";
import HeroSection from "./HeroSection";
import SchoolLocalization from "./SchoolLocalization";
import TripParticipants from "./TripParticipants";
import WordTales from "./WordTales";
import VideoTales from "./VideoTales";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import LiveLearn from "./LiveLearn";
import MakingDifference from "./MakingDifference";
import DownArrow from "./DownArrow";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { BASE_URL } from "../../../const";
import Culinary from "./Culinary";

export const PreviewItinerary = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [itinerary, setItinerary] = useState({});
  const [stories, setStories] = useState([]);
  const [friendships, setFriendships] = useState([]);
  const [livelearns, setLiveLearns] = useState([]);
  const [makingDifference, setMakingDifference] = useState([]);
  const [accomodationReviews, setAccomodationReviews] = useState("");
  const [attractionsReviews, setAttractionsReviews] = useState("");
  const [culinaryReviews, setCulinaryReviews] = useState("");
  const [foodReviews, setFoodReviews] = useState(null);
  const [accReviews, setAccReviews] = useState(null);
  const [atrReviews, setAtrReviews] = useState(null);
  const [classes, setClasses] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [vlogs, setVlogs] = useState([]);
  const [viaRoutes, setViaRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [primary, setPrimary] = useState(false);
  const getItinerary = () => {
    axios
      .get(
        `${BASE_URL}/api/itineraries/${searchParams.get("Slug")}?populate=deep`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        }
      )
      .then((res) => {
        setItinerary(res.data.data.attributes);
        setStories(res.data.data.attributes.Tales_To_Tell);
        setFriendships(res.data.data.attributes.Friendships);
        setLiveLearns(res.data.data.attributes.Live_Learns);
        setMakingDifference(res.data.data.attributes.Making_Differences);
        setAccomodationReviews(res.data.data.attributes.Accomodation_Reviews);
        setAttractionsReviews(res.data.data.attributes.Attractions_Reviews);
        setCulinaryReviews(res.data.data.attributes.Culinary_Reviews);
        setClasses(res.data.data.attributes.Classes);
        setGalleries(res.data.data.attributes.Galleries);
        setVlogs(res.data.data.attributes.Vlogs);
        setViaRoutes(res.data.data.attributes.Via_Routes);
        setFoodReviews(res.data.data.attributes.Food_Reviews);
        setAccReviews(res.data.data.attributes.Accomodation_Reviews_new);
        setAtrReviews(res.data.data.attributes.Attractions_Reviews_new);
        setPrimary(res.data.data.attributes.primary);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getItinerary();
  }, []);
  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);

  const nextSectionScroll = () => {
    const elements = document.getElementsByClassName("iti_section");
    const index = currentSection + 1;
    console.log(elements);
    const el = elements[index];
    if (el) {
      console.log(el.id);
      if (
        el.id === "GALLERIES" ||
        el.id === "REVIEWS" ||
        el.id === "LIVE_LEARN" ||
        el.id === "MAKING_DIFFERENCE"
      ) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      setCurrentSection(index);
    }
  };
  const prevSectionScroll = () => {
    const elements = document.getElementsByClassName("iti_section");
    const index = currentSection - 1;
    const el = elements[index];
    if (el) {
      if (
        el.id === "GALLERIES" ||
        el.id === "REVIEWS" ||
        el.id === "LIVE_LEARN" ||
        el.id === "MAKING_DIFFERENCE"
      ) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      setCurrentSection(index);
    }
  };

  return (
    <Grid
      className="marginTop_MainGrid grid"
      style={{ minHeight: "60vh" }}
      centered
      relaxed
    >
      {loading ? (
        <Loader active={true}></Loader>
      ) : (
        <>
          <Button
            className="sticky-btn"
            onClick={nextSectionScroll}
          >
            Next Section
          </Button>
          {currentSection > 0 && (
            <Button className="sticky-btn-previous" onClick={prevSectionScroll}>
                Previous Section
            </Button>
          )}
          <HeroSection itinerary={itinerary} />
          <DownArrow />
          <TripMap itinerary={itinerary} />
          <Routes itinerary={itinerary} via_routes={viaRoutes} />
          <SchoolLocalization itinerary={itinerary} />
          <TripParticipants itinerary={itinerary} />
          <SupervisingTeachers itinerary={itinerary} />
          {!primary && <ClassStudents classes={classes} />}
          {vlogs.length > 0 && <VideoTales vlogs={vlogs} />}
          <Gallery galleries={galleries} primary={primary}/>
          {friendships.length > 0 && <Friendships friendships={friendships} />}
          {accReviews !== null && (
            <Reviews
              reviews={accReviews}
              title={"sleeping well - accomodation reviews"}
            />
          )}
          {atrReviews !== null && (
            <Reviews
              reviews={atrReviews}
              title={primary ? "learn...integrate...enjoy": "open your mind - attractions reviews"}
            />
          )}
          {foodReviews !== null && (
            <Reviews
              reviews={foodReviews}
              title={"lip smacking - culinary reviews"}
            />
          )}
          <WordTales stories={stories} primary={primary}/>
          {!primary && <LiveLearn livelearns={livelearns} />}
          <MakingDifference makingDifference={makingDifference} primary={primary}/>
          <Grid.Row style={{ height: "40px" }}></Grid.Row>
        </>
      )}
    </Grid>
  );
};

export default PreviewItinerary;
