import React, {useRef, useEffect} from "react";
import { Grid } from "semantic-ui-react";
import AOS from 'aos';
import "aos/dist/aos.css";


export const SchoolLocalization = ({itinerary}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
      
    return (

        <Grid.Row id="SCHOOL_LOC" className="iti_section">
            <Grid stackable centered padded>
                <Grid.Column computer={6} mobile={12} tablet={12} verticalAlign='middle' data-aos="fade-left">
                    <h2>our school</h2><h3 className='schoolName'>{itinerary.School_Name}</h3> <a href={itinerary.School_Link} target="_blank"><h3>School Website</h3></a>
                </Grid.Column>
                <Grid.Column computer={6} mobile={10} tablet={10} verticalAlign='middle' data-aos="fade-right">
                    <div className="localization" dangerouslySetInnerHTML={ {__html:itinerary.Google_Maps_Link}}/>
                </Grid.Column>
            </Grid>
        </Grid.Row>

    )
}

export default SchoolLocalization;