import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import departure from "../img/departure.svg";
import landing from "../img/landing.svg";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Line,
  Annotation,
  Marker,
  ZoomableGroup
} from "react-simple-maps";
import AOS from 'aos';
import "aos/dist/aos.css";
import map from "./map.json"

export const TripMap = ({ itinerary }) => {
  useEffect(() => {
    AOS.init({duration: 800, disable: 'mobile'});
  }, []);
  
  const geoUrl = map;
  return (
    <Grid.Row data-aos="fade-right" id="TRIP" className="iti_section">
      <Grid.Column
        only="computer"
        computer={12}
        className="max_width_trip_map_container"
      >
        <img loading="lazy" src={departure} alt="departure_icon" />
        <span className="tripDates">{itinerary.Date_From}</span>
      </Grid.Column>
      <Grid.Column
        only="tablet mobile"
        tablet={14}
        mobile={14}
        className="max_width_trip_map_container"
      >
        <img
          loading="lazy"
          src={departure}
          alt="departure_icon"
          className="planeIcon"
        />
        <span className="tripDates">{itinerary.Date_From}</span>
      </Grid.Column>
      <Grid.Column only="computer" computer={12} textAlign="center">

        <ComposableMap
          style={{ border: "3px solid #2E7EB8", borderRadius: "49px" }}
          projection="geoEqualEarth"
          className="tripMap"
          projectionConfig={{
            scale: 300,
            center: [0, 15],
          }}
        >
          <ZoomableGroup center={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]} zoom={2}>
          <Graticule stroke="#DDD" />
          <Geographies
            geography={geoUrl}
            fill="#B82E60"
            stroke="#FFFFFFFF"
            strokeWidth={1.0}
          >
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography key={geo.rsmKey} geography={geo} />
              ))
            }
          </Geographies>
          <Line
            from={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
            to={[
              parseInt(itinerary.GPS_Longitude_Destination),
              parseInt(itinerary.GPS_Latitude_Destination),
            ]}
            stroke="#2E7EB8"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <Annotation
            subject={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
            dx={240}
            dy={-10}
            connectorProps={{
              stroke: "",
              strokeWidth: 5,
              strokeLinecap: "round",
            }}
          >
            <text
              x="-8"
              textAnchor="end"
              alignmentBaseline="middle"
              fill="#2E7EB8"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              {itinerary.Origin}
            </text>
          </Annotation>
          <Annotation
            subject={[
              parseInt(itinerary.GPS_Longitude_Destination),
              parseInt(itinerary.GPS_Latitude_Destination),
            ]}
            dx={-10}
            dy={10}
            connectorProps={{
              stroke: "",
              strokeWidth: 5,
              strokeLinecap: "round",
            }}
          >
            <text
              x="-8"
              textAnchor="end"
              alignmentBaseline="middle"
              fill="#2E7EB8"
              style={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              {itinerary.Destination}
            </text>
          </Annotation>
          <Marker
            coordinates={[
              parseInt(itinerary.GPS_Longitude_Destination),
              parseInt(itinerary.GPS_Latitude_Destination),
            ]}
          >
            <circle r={6} fill="#F53" />
          </Marker>
          <Marker
            coordinates={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
          >
            <circle r={6} fill="#F53" />
          </Marker>
          </ZoomableGroup>
        </ComposableMap>
      </Grid.Column>
      <Grid.Column
        only="tablet mobile"
        tablet={14}
        mobile={14}
        textAlign="center"
      >
        
        <ComposableMap
          style={{ border: "3px solid #2E7EB8", borderRadius: "25px" }}
          projection="geoEqualEarth"
          className="tripMap"
          projectionConfig={{
            scale: 300,
            center: [0, 15],
          }}
        >
          <ZoomableGroup center={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]} zoom={4}>
          <Graticule stroke="#DDD" />
          <Geographies
            geography={geoUrl}
            fill="#B82E60"
            stroke="#FFFFFFFF"
            strokeWidth={1.0}
          >
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography key={geo.rsmKey} geography={geo} />
              ))
            }
          </Geographies>
          <Line
            from={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
            to={[
              parseInt(itinerary.GPS_Longitude_Destination),
              parseInt(itinerary.GPS_Latitude_Destination),
            ]}
            stroke="#2E7EB8"
            strokeWidth={3}
            strokeLinecap="round"
          />
          <Annotation
            subject={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
            dx={170}
            dy={-10}
            connectorProps={{
              stroke: "",
              strokeWidth: 5,
              strokeLinecap: "round",
            }}
          >
            <text
              x="-8"
              textAnchor="end"
              alignmentBaseline="middle"
              fill="#2E7EB8"
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              {itinerary.Origin}
            </text>
          </Annotation>
          <Annotation
            subject={[
                parseInt(itinerary.GPS_Longitude_Destination),
                parseInt(itinerary.GPS_Latitude_Destination),
            ]}
            dx={-10}
            dy={10}
            connectorProps={{
              stroke: "",
              strokeWidth: 5,
              strokeLinecap: "round",
            }}
          >
            <text
              x="-8"
              textAnchor="end"
              alignmentBaseline="middle"
              fill="#2E7EB8"
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              {itinerary.Destination}
            </text>
          </Annotation>
          <Marker
            coordinates={[
              parseInt(itinerary.GPS_Longitude_Destination),
              parseInt(itinerary.GPS_Latitude_Destination),
            ]}
          >
            <circle r={6} fill="#F53" />
          </Marker>
          <Marker
            coordinates={[
              parseInt(itinerary.GPS_Longitude_Origin),
              parseInt(itinerary.GPS_Latitude_Origin),
            ]}
          >
            <circle r={6} fill="#F53" />
          </Marker>
          </ZoomableGroup>
        </ComposableMap>
      </Grid.Column>
      <Grid.Column
        only="computer"
        computer={12}
        className="max_width_trip_map_container"
        textAlign="right"
      >
        <>
          <img loading="lazy" src={landing} alt="landing_icon" />
          <span className="tripDates">{itinerary.Date_To}</span>
        </>
      </Grid.Column>
      <Grid.Column
        only="tablet mobile"
        tablet={14}
        mobile={14}
        className="max_width_trip_map_container"
        textAlign="right"
        verticalAlign="top"
      >
        <img
          loading="lazy"
          src={landing}
          alt="landing_icon"
          className="planeIcon"
        />
        <span className="tripDates">{itinerary.Date_To}</span>
      </Grid.Column>
    </Grid.Row>
  );
};

export default TripMap;
