import React from "react";
import { Grid } from "semantic-ui-react";
import { BASE_URL } from "../../../const";

export const IndividualReview = ({ review }) => {
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column
          computer={14}
          mobile={14}
          tablet={12}
          textAlign="center"
          style={{ paddingBottom: 5 }}
        >
          <h3>{review.Title}</h3>
          <p style={{ fontStyle: "italic", fontWeight: "bold" }}>
            {" "}
            {review.Highlight_sentence}
          </p>
          <p style={{ fontWeight: "bold" }}>{review.Author !== null && (<>Written by {review.Author}</>)}</p>
        </Grid.Column>
        {review.Body_paragraph.map((body, index) => {
          return (
            <>
              {body?.photos.data !== null &&
                body?.photos.data.map((photo, index) => {
                  return (
                    <Grid.Column
                      computer={6}
                      mobile={14}
                      key={photo.attributes.name}
                    >
                      {photo.attributes.formats.hasOwnProperty('small') ? (
                        <img
                          src={`${BASE_URL}${photo.attributes.formats.small.url}`}
                        />
                      ) : (
                        <img src={`${BASE_URL}${photo.attributes.url}`} />
                      )}
                    </Grid.Column>
                  );
                })}

              <Grid.Column
                computer={14}
                mobile={14}
                tablet={12}
                style={{ padding: 25 }}
                key={index}
              >
                <p>{body.text}</p>
              </Grid.Column>
            </>
          );
        })}
      </Grid.Row>
      {review.websites !== null && (
        <Grid.Column
          computer={14}
          mobile={14}
          tablet={12}
          style={{ padding: 25 }}
        >
          <h4>Websites</h4>
          {review.websites.split(",").map((website) => {
            return (
              <p key={website}>
                <a href={website} target="_blank">
                  {website}
                </a>
              </p>
            );
          })}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default IndividualReview;
