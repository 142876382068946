import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import Class from "./Class";
import AOS from 'aos';
import "aos/dist/aos.css";

export const ClassStudents = ({classes}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (
        <Grid.Row id="CLASS_STUDENTS" className="iti_section">
            <Grid centered>
                <Grid.Row>
                    <Grid.Column computer={12} textAlign='center' data-aos='fade-up'>
                        <h2>who took part</h2>
                    </Grid.Column>
                </Grid.Row>
                {classes.map((val)=>{
                     return <Class key={val.id} class_val={val} />
                })}
               
            </Grid>
        </Grid.Row>
    )
}

export default ClassStudents