import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import RoutePoint from "../RoutePoint";
import AOS from "aos";
import "aos/dist/aos.css";

export const Routes = ({ itinerary, via_routes }) => {
  const [originPhoto, setOriginPhoto] = useState("");
  const [destinationPhoto, setDestinationPhoto] = useState("");

  useEffect(() => {
    if (itinerary.Origin_Photo.data !== null) {
      if (
        itinerary.Origin_Photo.data.attributes.formats.hasOwnProperty("large")
      ) {
        setOriginPhoto(
          itinerary.Origin_Photo.data.attributes.formats.large.url
        );
      } else {
        setOriginPhoto(itinerary.Origin_Photo.data.attributes.url);
      }
    }
    if (itinerary.Destination_Photo.data !== null) {
      if (
        itinerary.Destination_Photo.data.attributes.formats.hasOwnProperty(
          "large"
        )
      ) {
        setDestinationPhoto(
          itinerary.Destination_Photo.data.attributes.formats.large.url
        );
      } else {
        setDestinationPhoto(itinerary.Destination_Photo.data.attributes.url);
      }
    }
  }, [itinerary]);
  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);

  return (
    <Grid.Row id="ROUTES" className="iti_section">
      <Grid centered>
        <RoutePoint
          city={itinerary.Origin}
          direction="left"
          from={true}
          filename={originPhoto}
          index={0}
        />
        {via_routes.map((val, index) => {
          let file = "";
          if(val.Via_Photo.data !== null){
            if (val.Via_Photo.data.attributes.formats.hasOwnProperty("small")) {
              file = val.Via_Photo.data.attributes.formats.small.url;
            } else {
              file = val.Via_Photo.data.attributes.url;
            }
          }
          
          return (
            <RoutePoint
              key={val.id}
              city={val.Via_Place}
              direction={index % 2 === 0 ? "right" : "left"}
              filename={file}
              via={true}
              index={1}
            />
          );
        })}
        {/*  */}
        <RoutePoint
          city={itinerary.Destination}
          direction="right"
          destination={true}
          filename={destinationPhoto}
          index={2}
        />
      </Grid>
    </Grid.Row>
  );
};

export default Routes;
