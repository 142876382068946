import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import AOS from 'aos';
import "aos/dist/aos.css";
import { BASE_URL } from "../../../const";

export const HeroSection = ({itinerary}) => {
    const [largeImage, setLargeImage] = useState('')
    const [smallImage, setSmallImage] = useState('')
    const [smallImageB, setSmallImageB] = useState('')
    useEffect(()=>{
            if(itinerary.Large_Image !== '' & itinerary.Large_Image.data !== null){
                if(itinerary.Large_Image.data.attributes.formats.hasOwnProperty('medium')){
                    let file = `${BASE_URL}`+itinerary.Large_Image.data.attributes.formats.medium.url
                    setLargeImage(file)
                }else{
                    let file = `${BASE_URL}`+itinerary.Large_Image.data.attributes.url
                    setLargeImage(file)
                }
            }
            if(itinerary.Small_Image_1 !== '' & itinerary.Small_Image_1.data !== null){
                if(itinerary.Small_Image_1.data.attributes.formats.hasOwnProperty('small')){
                    let file = `${BASE_URL}`+itinerary.Small_Image_1.data.attributes.formats.small.url
                    setSmallImage(file)}else{
                    let file = `${BASE_URL}`+itinerary.Small_Image_1.data.attributes.url
                    setSmallImage(file)
                    }
            }

            if(itinerary.Small_Image_2 !== '' & itinerary.Small_Image_2.data !== null){
                if(itinerary.Small_Image_2.data.attributes.formats.hasOwnProperty('small')){
                    let file = `${BASE_URL}`+itinerary.Small_Image_2.data.attributes.formats.small.url
                    setSmallImageB(file)
                }else{
                    let file = `${BASE_URL}`+itinerary.Small_Image_2.data.attributes.url
                    setSmallImageB(file)
                }
            }
       
    },[itinerary])
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (
        <Grid.Row data-aos="fade-up" id="HERO_SECTION" className="iti_section">
                <Grid.Column only='computer' computer={12}>
                    <div className='display_rectangle_1' style={{backgroundImage: `url("${largeImage}")`, objectFit:'cover', backgroundSize:'cover'}}>
                    {itinerary.Title}
                        <div className='display_rectangle_2' style={{backgroundImage: `url("${smallImage}")`,  objectFit:'cover', backgroundSize:'cover'}}></div>
                        <div className='display_rectangle_3' style={{backgroundImage: `url("${smallImageB}")`,  objectFit:'cover', backgroundSize:'cover'}}></div>
                    </div>
                </Grid.Column>
                <Grid.Column only='tablet mobile' tablet={14} mobile={14}>
                    <div className='display_rectangle_1'  style={{backgroundImage: `url("${largeImage}")`, objectFit:'cover', backgroundSize:'cover'}}>
                        {itinerary.Title}
                    </div>
                </Grid.Column>
                <Grid.Column only='computer' computer={10} floated='right' textAlign='left' verticalAlign='bottom' style={{marginTop:'40px'}}>
                    <p>{itinerary.Main_Paragraph}</p>
                </Grid.Column>
                <Grid.Column only='tablet mobile' tablet={14} mobile={14} textAlign='center' style={{marginTop:'32px'}}>
                    <p>{itinerary.Main_Paragraph}</p>
                </Grid.Column>
        </Grid.Row>
    )
}

export default HeroSection;