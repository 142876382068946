import React,{useEffect} from "react";
import { Grid } from 'semantic-ui-react'
import PhotoTextSection from "./PhotoTextSection";
import AOS from 'aos';
import "aos/dist/aos.css";
export const MakingDifference = ({ makingDifference, primary }) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (
        <Grid.Row data-aos="fade-left" id="MAKING_DIFFERENCE" className="iti_section">
            <Grid centered>
                <Grid.Row>
                    {primary ? <><Grid.Column computer={12} mobile={12} textAlign='center'>
                        <h2>classwork far</h2>
                    </Grid.Column>
                    <Grid.Column computer={12} mobile={12} textAlign='center' className='Title_underline'>
                        <h1>from the CLASSroom</h1>
                    </Grid.Column></>:<><Grid.Column computer={12} mobile={12} textAlign='center'>
                        <h2>making</h2>
                    </Grid.Column>
                    <Grid.Column computer={12} mobile={12} textAlign='center' className='Title_underline'>
                        <h1>a DIFFerence</h1>
                    </Grid.Column></>}
                    
                </Grid.Row>
            </Grid>
            <Grid.Column computer={14} mobile={16}>
                {makingDifference.map((val) => {
                    return <PhotoTextSection key={val.id} section={val} />
                })}

            </Grid.Column>
        </Grid.Row>

    )
}

export default MakingDifference;