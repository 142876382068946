import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import personsIcon from '../img/persons.svg';
import AOS from 'aos';
import "aos/dist/aos.css";


export const TripParticipants = ({itinerary}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
      
    return (

        <Grid.Row id="TRIP_PARTICIPANTS" className="iti_section">
            <Grid centered data-aos="flip-up">
                <Grid.Column computer={6} mobile={12} tablet={12} verticalAlign='middle' textAlign='center'>
                    <h2>number of trip participants</h2>
                </Grid.Column>
                <Grid.Column computer={6} mobile={12} tablet={12} textAlign='center' verticalAlign='middle'>
                    <h3 className='number'><img loading="lazy" src={personsIcon} alt='persons icon' className='personsIcon' />{itinerary.Number_of_Participants}</h3>
                </Grid.Column>
            </Grid>
        </Grid.Row>

    )
}

export default TripParticipants;
