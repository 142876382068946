import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import IndividualFriendship from "./IndividualFriendship";
import AOS from 'aos';
import "aos/dist/aos.css";
/* eslint-disable no-unused-expressions */
export const Friendships = ({ friendships }) => {

    const [friendshipsArr, setFriendshipsArr] = useState(friendships)
    const [activeFriendship, setActiveFriendship] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    useEffect(()=>{
        setActiveFriendship(friendshipsArr[currentIndex])
    })
    useEffect(()=>{
        setActiveFriendship[friendshipsArr[currentIndex]]
    },[currentIndex])
    const handleFriendshipSwitch = (direction) => {

        if(direction === 'right'){
            if((currentIndex + 1) < friendshipsArr.length){
                setCurrentIndex(currentIndex + 1)
            }
            if(currentIndex + 1 === friendshipsArr.length){
                setCurrentIndex(0)
            }
            
        }else{
            if(currentIndex > 0){
                setCurrentIndex(currentIndex - 1)
            }
            if(currentIndex - 1 < 0){
                setCurrentIndex(friendshipsArr.length - 1)
            }
        }

        
    }

    return (

        <Grid.Row data-aos="fade-up" id="FRIENDSHIPS" className="iti_section">
            <Grid.Column computer={12} mobile={16} textAlign='center'>
                <h1>new friendships</h1>
            </Grid.Column>

            <Grid.Column computer={16} mobile={16}>
                <Grid.Row>
                {friendships.length > 0 && activeFriendship.hasOwnProperty('id') ? <IndividualFriendship key={activeFriendship.id} friendship={activeFriendship} handleFriendshipSwitch={handleFriendshipSwitch} /> : null}
                 
                </Grid.Row>
            </Grid.Column>

        </Grid.Row>


    )
}


export default Friendships