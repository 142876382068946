import React, {useState, useEffect} from "react";
import { Grid } from "semantic-ui-react";
import { BASE_URL } from "../../../const";

export const Story = ({story}) => {
    const [photo, setPhoto] = useState('')
    useEffect(()=>{
        if(story.Photo.data !== null){
        if(story.Photo !== null && story.Photo.data.attributes.formats.hasOwnProperty('small')){
            let file = `${BASE_URL}`+story.Photo.data.attributes.formats.small.url
            setPhoto(file)
        }else{
            let file = `${BASE_URL}`+story.Photo.data.attributes.url
            setPhoto(file)
        }}
    },[story])


    return (
        <Grid centered>
            <Grid.Row> 
            <Grid.Column computer={4} mobile={14} tablet={12} textAlign='center'>
                <div className='talesImgs' style={{backgroundImage: `url("${photo}")`, objectFit:'cover', backgroundSize:'cover'}}></div>
            </Grid.Column>
            <Grid.Column computer={12} mobile={14} tablet={12} textAlign='left'>
                <h3>{story.Title}</h3>
                <p className="scrollableP">{story.Paragraph}</p>              
            </Grid.Column>
            </Grid.Row>
        </Grid>

    )
}

export default Story;