import React, {useRef, useLayoutEffect} from "react";
import { Grid } from "semantic-ui-react";

export const Videos = ({vlogs}) => {

    return (

            vlogs.map((val)=>{
                return <Grid.Column verticalAlign="bottom" key={val.id} computer={6} mobile={14} tablet={14} className='videoColumn'><h3 style={{marginTop:'10px', textAlign:'center'}}>{val.Vlog_Title}</h3><div className="rotation" key={val.id} dangerouslySetInnerHTML={{__html:val.Vlog_Link}} allowFullScreen/></Grid.Column>

            })
    )

}

export default Videos;