import React, { useState, useEffect } from "react";
import { Grid, Icon } from "semantic-ui-react";
import personImg from "../img/person.jpg";
import left_arrow from "../img/left_arrow.svg";
import right_arrow from "../img/right_arrow.svg";
import website_icon from "../img/website_icon.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { BASE_URL } from "../../../const";

export const IndividualFriendship = ({
  friendship,
  handleFriendshipSwitch,
}) => {
  const [photo, setPhoto] = useState("");
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 150) {
      handleFriendshipSwitch("right");
    }

    if (touchStart - touchEnd < -150) {
      handleFriendshipSwitch("left");
    }
  }
  useEffect(() => {
    if (friendship.Friend_Photo.data !== null) {
      if (
        friendship.Friend_Photo.data.attributes.formats.hasOwnProperty("medium")
      ) {
        let file =
          `${BASE_URL}` +
          friendship.Friend_Photo.data.attributes.formats.medium.url;
        setPhoto(file);
      } else {
        let file = `${BASE_URL}` + friendship.Friend_Photo.data.attributes.url;
        setPhoto(file);
      }
    }
  }, [friendship]);

  return (
    <Grid centered>
      <Grid.Column only="computer" computer={2} verticalAlign="middle">
        <img
          loading="lazy"
          src={left_arrow}
          className="friendshipNav"
          onClick={() => {
            handleFriendshipSwitch("left");
          }}
        />
      </Grid.Column>

      <Grid.Column
        computer={12}
        mobile={14}
        tablet={12}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <Grid stackable centered data-aos="flip-left">
          <Grid.Column only="computer" computer={6}>
            <div className="relative_container">
              {" "}
              <img loading="lazy" src={photo} className="image_container" />
            </div>
          </Grid.Column>
          <Grid.Column only="mobile tablet" mobile={12} tablet={12}>
            <div className="relative_container">
              {" "}
              <img
                loading="lazy"
                className="left_arrow_img friendshipNav"
                src={left_arrow}
                onClick={() => {
                  handleFriendshipSwitch("left");
                }}
              />{" "}
              <img
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                src={photo}
                className="image_container"
              />
              <img
                className="right_arrow_img friendshipNav"
                src={right_arrow}
                onClick={() => {
                  handleFriendshipSwitch("right");
                }}
              />
            </div>
          </Grid.Column>
          <Grid.Column computer={10} tablet={12} mobile={14}>
            <div style={{ textAlign: "left" }}>
              <h3>{friendship.Friend_Name}</h3>
              <Icon.Group className="icons">
                {friendship.Website_Link_1 !== null ? (
                  <a target="_blank" href={friendship.Website_Link_1}>
                    <img
                      loading="lazy"
                      src={website_icon}
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                ) : null}
                {friendship.Website_Link_2 !== null ? (
                  <a target="_blank" href={friendship.Website_Link_2}>
                    <img
                      loading="lazy"
                      src={website_icon}
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                ) : null}
                {friendship.Website_Link_3 !== null ? (
                  <a target="_blank" href={friendship.Website_Link_3}>
                    <img
                      loading="lazy"
                      src={website_icon}
                      style={{ marginRight: "5px" }}
                    />
                  </a>
                ) : null}
              </Icon.Group>
              <p className="scrollableP">{friendship.Paragraph}</p>
            </div>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column only="computer" computer={2} verticalAlign="middle">
        <img
          src={right_arrow}
          className="friendshipNav"
          onClick={() => {
            handleFriendshipSwitch("right");
          }}
        />
      </Grid.Column>
    </Grid>
  );
};

export default IndividualFriendship;
