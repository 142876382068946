import React from "react";
import { Grid } from 'semantic-ui-react'
import { BASE_URL } from "../../const";

export const RoutePoint = ({city, direction, via, destination, from, filename }) => {
    let photo = ''
    try{
        photo = `${BASE_URL}`+filename
    }catch{
        photo = ''
    }
    
    return (
        <>
            <Grid.Column only='computer'  textAlign='center' computer={12} style={{ marginTop: '80px' }} data-aos="fade-left">
                <div className='route_square' style={{backgroundImage: `url("${photo}")` , objectFit:'cover', backgroundSize:'cover'}}>
                    <div className={direction === 'right' ? 'routeNameRight' : 'routeNameLeft'}>
                        <div style={{ display: 'block' }}>
                            {destination && <div className='from'>to</div>}
                            {via && <div className='from'>via</div>}
                            {from && <div className='from'>from</div>}
                            <div className='city'>{city}</div>
                        </div>
                    </div>
                    {destination ? null : <div className="vertical"></div>}

                </div>
            </Grid.Column>
            <Grid.Column only='mobile tablet' tablet={12} mobile={12} style={from ? null : { marginTop: '80px' }} data-aos="fade-left">
                <div className='route_square' style={{backgroundImage: `url("${photo}")` , objectFit:'cover', backgroundSize:'cover'}}>
                    <div className='routeNameRight'>
                        <div style={{ display: 'block' }}>
                            {destination && <div className='from'>to</div>}
                            {via && <div className='from'>via</div>}
                            {from && <div className='from'>from</div>}
                            <div className='city'>{city}</div>
                        </div>
                    </div>
                    {destination ? null : <div className="vertical"></div>}

                </div>
            </Grid.Column>
        </>

    )
}

export default RoutePoint;