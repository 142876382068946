import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import arrow_img from '../img/arrow_link.svg';
import { BASE_URL } from "../../../const";

export const Itinerary = ({itinerary, index}) => {
    const [largeImage, setLargeImage] = useState('')
    useEffect(()=>{
        if(itinerary.Large_Image.data !== null && itinerary.Large_Image.data.attributes.formats.hasOwnProperty('large')){
            let file =`${BASE_URL}`+itinerary.Large_Image.data.attributes.formats.large.url
            setLargeImage(file)
        }else{
            if(itinerary.Large_Image.data !== null){
                let file =`${BASE_URL}`+itinerary.Large_Image.data.attributes.url
                setLargeImage(file)
            }
        }
    },[itinerary])
    return (

        <Grid.Row stretched className='itinerary_row'>
            <Grid.Column width={8}>
                <div className={'itinerary_logo'} style={{backgroundImage: `url("${largeImage}")`,  objectFit:'cover', backgroundSize:'cover'}}></div>
              
            </Grid.Column>
            <Grid.Column width={6} className={'itinerary_details'}>
                <div className='itinerary_title'>{itinerary.Title}</div>
                <div className='itinerary_box_text'>DATE: <span style={{ fontWeight: 'bold' }}>{itinerary.Date_From} - {itinerary.Date_To}</span></div>
                <div className='itinerary_box_text'>FROM: <span style={{ fontWeight: 'bold' }}>{itinerary.Origin}</span></div>
                <div className='itinerary_box_text'>TO: <span style={{ fontWeight: 'bold' }}>{itinerary.Destination}</span></div>
                <div className='itinerary_link' style={{ verticalAlign: 'middle' }}><a target='_blank' href={itinerary.TrailerLink}>Trailer</a></div>
                <div className='itinerary_link' style={{ verticalAlign: 'middle' }}><span style={{ marginRight: '15px' }}><Link to={`${itinerary.Slug}`}>Intinerary page </Link></span> <img src={arrow_img} alt='arrow_link'></img></div>
            </Grid.Column>
        </Grid.Row>
    )
}

export default Itinerary



