import React, { useEffect, useId } from "react";
import { Grid } from "semantic-ui-react";
import AOS from "aos";
import "aos/dist/aos.css";
import IndividualReview from "./IndividualReview";
/* eslint-disable no-unused-expressions */
export const Reviews = ({ title, reviews }) => {
  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);
  const id = useId()
  return reviews.review.length > 0 ? (
    <Grid.Row data-aos="fade-up" id="REVIEWS" className="iti_section">
      <Grid.Column computer={12} mobile={16} textAlign="center">
        <h1>{title}</h1>
      </Grid.Column>

      <Grid.Column computer={16} mobile={16}>
        <Grid.Row>
          {reviews.review.map((individualReview) => {
            return <IndividualReview key={`${id}-${individualReview.Author}`} review={individualReview} />;
          })}
        </Grid.Row>
      </Grid.Column>
    </Grid.Row>
  ) : null;
};

export default Reviews;
