import React from "react";
import Main from "./components/Main";
import {BrowserRouter as Router, Route,   Routes } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
export const App = () => {
    return (
      <Router>
        <Routes>
        <Route path='/*' element={<Main />}></Route>
        </Routes>
        
        
      </Router>
    );
  
}

export default App;