import React, { useState, useEffect } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import a from "./img/a.jpg";
import b from "./img/b.jpg";
import c from "./img/c.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import fresheyesKit from "../downloads/Fresh_Eyes_Classroom_Prep_Kit_and_Teacher_Materials_2024.zip";
import fresheeysCareerCharts from "../downloads/Fresh_Eyes_Career_Guidance_charts.pdf";
import slides from "../downloads/slides_summary.pdf";
import fresh_trip from "../downloads/fresh_trip.pdf";

export const About = () => {
  const [showMethodParagraph, setShowMethodParagraph] = useState(false);
  const [showBackstoryParagraph, setShowBackstoryParagraph] = useState(false);
  const [showClassroomPrepParagraph, setShowClassroomPrepParagraph] =
    useState(false);

  useEffect(() => {
    AOS.init({ duration: 800, disable: "mobile" });
  }, []);
  return (
    <Grid className="aboutGrid" stackable centered columns="equal">
      <Grid.Row>
        <Grid.Column only="computer tablet" computer={12} tablet={12}>
          <div
            className="rectangle_1"
            style={{
              backgroundImage: `url(${c})`,
              objectFit: "cover",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            data-aos="fade-up"
          >
            <div
              className="rectangle_2"
              style={{
                backgroundImage: `url(${a})`,
                objectFit: "cover",
                backgroundSize: "cover",
              }}
              data-aos="fade-left"
            ></div>
            <div
              className="rectangle_3"
              style={{
                backgroundImage: `url(${b})`,
                objectFit: "cover",
                backgroundSize: "cover",
              }}
              data-aos="fade-right"
            ></div>
          </div>
        </Grid.Column>
        <Grid.Column only="mobile" mobile={12}>
          <div
            className="rectangle_1"
            style={{
              backgroundImage: `url(${c})`,
              objectFit: "cover",
              backgroundSize: "cover",
            }}
            data-aos="fade-up"
          ></div>
        </Grid.Column>
        <Grid.Column
          computer={12}
          tablet={12}
          mobile={12}
          className="aboutColumn"
          verticalAlign="top"
        >
          <h5 data-aos="fade-up">
            Join Us At
            <br />
            FRESH <span className="E">E</span>Y<span className="E">E</span>S
            <br />
            Share your innovative school trips with the world
            <br />
            <span className="download_text">
              For inspiration on how to transform your own school trips, we
              invite teachers to take advantage of the ready-to-use materials in
              the{" "}
              <a
                href="/api/download"
                download="Fresh_Eyes_Classroom_Prep_Kit_and_Teacher_Materials.zip"
                style={{ textDecoration: "underline" }}
              >
                Classroom Prep Kit
              </a>{" "}
              (for learners aged 15+) and the Production Tasks: Your Job - My
              Gratitude or Wise Guys Map Their Own Path (for pupils aged 11-14).
            </span>
          </h5>

          <h3>Our Backstory</h3>
          <p className="about_paragraph" data-aos="fade-left">
            <b>Fresh Eyes school trips</b> totally change the game! The
            predictable and generic school trip is a thing of the past. Students
            and pupils can now enjoy school trips of their very own making.
            <br />
            <br />
            We bring you an entirely new concept of school trips that are
            educationally exquisite.{" "}
            <b>
              Created by students themselves with the collaboration of their
              teachers.
            </b>
          </p>
          <p
            className="about_paragraph"
            hidden={!showBackstoryParagraph}
            data-aos="fade-up"
          >
            <b>Fresh Eyes school trips</b> are away-from-school adventures that
            inspire both students and teachers. They are infused with personal
            gratification for students, discovering the mysteries of a
            destination, finding beauty in less obvious places and learning by
            doing. <br />
            <br />
            Within the space of a couple days, a week or even just a few hours,
            a <b>Fresh Eyes</b> school trip gifts its participants with an
            authentic slice of travel that broadens the mind, excites the senses
            and creates memories to cherish forever. <br />
            <br />
            The typical school trip that students will participate in, is
            usually a product that teachers buy from a catalogue of offers
            especially marketed for schools. <br />
            <br />
            You know what they look like: a routine menu of offers to spend time
            on premises situated next to a lake or nestled in a forest, or
            perhaps within a thirty minute walk to the seaside. As a rule,
            accommodations aren’t considered to be serious contributions to the
            experience, so they are generally in dull buildings that lack
            character, or in ultra basic cabins well past their prime. And for
            sure, from one school trip or another, you have memories of the
            smell of mildew growing where you definitely didn’t want it to grow!{" "}
            <br />
            <br />
            Mundane school cafeteria meals are usually included in these trips,
            along with a list of run-of-the-mill attractions that are served up
            with options such as outdoor courts, indoor table tennis and perhaps
            some paintball. Maybe a treasure hunt. Such experiences, duplicated
            in schools throughout Europe and around the world, is in a word:
            generic.
            <br />
            <br />
            <b>Fresh Eyes school trips</b> totally change the game! Personal
            gratification for students… Mystery… Beauty… Learning!
          </p>
          <Button
            style={{ backroundColor: "transparent" }}
            onClick={() => {
              setShowBackstoryParagraph(!showBackstoryParagraph);
            }}
            content={
              showBackstoryParagraph ? "Wrap up section..." : "Learn more..."
            }
            floated="left"
          />
          <br />
          <h5 data-aos="fade-up">
            FRESH <span className="E">E</span>Y<span className="E">E</span>S
            <br />
            Exquisite Educational Journeys
          </h5>
          <h3 data-aos="fade-up">The Method</h3>
          <p className="about_paragraph" data-aos="fade-right">
            <b>Fresh Eyes</b> trips is the offspring of the innovative{" "}
            <i>Ten Score</i> methodology for English Language Teaching, which
            has been under development by Carolyn Trotman-Grabek for primary and
            secondary schools in Poland since 2015.{" "}
          </p>
          <p
            className="about_paragraph"
            hidden={!showMethodParagraph}
            data-aos="fade-up"
          >
            <i>Ten Score</i> offers a viable framework to assist English
            teachers in embedding these 3 pedagogic strands into their classroom
            curricula by using English Language Teaching as a natural gateway to
            fuse socio-cognitive practices with social and emotional learning:
            <br />
            <br />
            1) teachers and learners 'catching' good character by being exposed
            to experiences which develop what Aristotle termed phronesis or the
            ability to demonstrate good judgement alongside the internalising of
            virtues such as honesty, compassion, tolerance, patience,
            resilience, courage, ambition and gratitude;
            <br />
            <br />
            2) reshaping and customising time spent in the classroom by way of
            Production Tasks, i.e., creative opportunities for learners to build
            their own repertoire of meaningful experiences through tasks that
            require them to research, appraise and produce real-life content. In
            other words — learning by doing;
            <br />
            <br />
            3) embracing and demonstrating the trickle-up accomplishments of
            'learner-owned investment', which require pupils to become
            pro-active in the very decision making processes governing their
            learning options and ensuing educational choices.
            <br />
            <br />
            In 2021, with 25 years experience in English Language Teaching and
            undeterred by the raging COVID-19 pandemic, Carolyn felt motivated
            by the worldwide crisis to offer her learners a language experience
            of the highest quality. To deliver on this goal, she took on the
            task of designing a new kind of school trip that would truly educate
            and inspire its participants while they were engaged in the
            step-by-step process of building a detailed custom-made itinerary
            themselves. <br />
            <br />
            According to the methodological template she created, the
            interdisciplinary learning process would begin in the classroom and
            comprise several stages — including learners carrying out in-depth
            research on: the place to be visited, local cuisine and folklore,
            transportation options and costs, air travel requirements, hacks for
            travelling ultralight, and much more.
            <br />
            <br />
            Ultimately, once pupils had covered the planning stages over a
            series of lessons that comprised elements from several subjects,
            including Geography, History, Economics, Principles of Business,
            Civics and of course English as the principal language of
            instruction and research — the culmination would be the physical
            realisation of the trip itself. <br />
            <br />
            Having been designed in detailed collaboration along with their
            teachers, learners are able to design such a travel plan that is far
            more than the regular school trip most of us know all too well. What
            they ultimately create is a multi-faceted educational adventure.
            <br />
            <br />
          </p>
          <Button
            onClick={() => {
              setShowMethodParagraph(!showMethodParagraph);
            }}
            content={
              showMethodParagraph ? "Wrap up section..." : "Learn more..."
            }
            floated="left"
          />
          <br />
          <h5 data-aos="fade-up">
            FRESH <span className="E">E</span>Y<span className="E">E</span>S
            <br />
            school trips shining a bold light onto paths <br />
            that lead towards valuable educational experiences. Here are
            ready-to-use lesson plans.
            <br />
          </h5>

          <h3 data-aos="fade-up">Classroom Prep Kit</h3>
          <p className="about_paragraph" data-aos="fade-right">
            These prepared and completely free, downloadable teacher materials
            cover 6-10 lessons, and comprise a TEN SCORE Production Task, which
            is a block of comprehensive exercises that thoroughly explore an
            educational goal by utilising a variety of classroom assignments, so
            that it caters to a wide range of learner types and abilities.
          </p>
          <p
            className="about_paragraph"
            hidden={!showClassroomPrepParagraph}
            data-aos="fade-up"
          >
            These materials are suitable for use with any class of 14 -19 year
            old students, to prepare them to design their own <b>Fresh Eyes</b>{" "}
            school trip. This in-depth preparatory stage is essential for the
            success of any <b>Fresh Eyes</b> school trip, which is centred on
            intense student collaboration and knowledge mining that is
            facilitated by teacher supervision. Just click on the button below
            to download the Classroom Prep Kit that comprises Teacher Materials
            for Methodological Insight, as well as a Production Task with
            Student Materials.
            <a href="mailto:fresheyesschooltrips@gmail.com">
              {" "}
              Get in touch with us any time
            </a>{" "}
            (hit this link to email us).
          </p>
          <Button
            onClick={() => {
              setShowClassroomPrepParagraph(!showClassroomPrepParagraph);
            }}
            content={
              showClassroomPrepParagraph
                ? "Wrap up section..."
                : "Learn more..."
            }
            floated="left"
          />
          <br />
          <br />
          <br />
          <Button
            className="blog_btn_long"
            floated="left"
            style={{ marginTop: "10px" }}
            animated="fade-right"
            data-aos="fade-up-left"
          >
            <a
              href={fresheyesKit}
              target="_blank"
              download="Fresh_Eyes_Classroom_Prep_Kit_and_Teacher_Materials.zip"
            >
              <Button.Content visible>
                Download Classroom Prep Kit
              </Button.Content>
              <Button.Content hidden>
                <Icon name="download"></Icon>
              </Button.Content>
            </a>
          </Button>
          <hr className="one_links" />
          <h3 data-aos="fade-up">
            Erasmus+ Project 2022-1-PL01-KA210-SCH-000081417
          </h3>
          <p className="about_paragraph" data-aos="fade-up">
            In addition to enhancing the existing project for the benefit of
            high school students, thanks to this Erasmus+ grant, a primary
            school program was created and tested. We invite primary school
            teachers to draw inspiration from the materials below to build your
            own Fresh Eyes school trips. And feel free to get in contact at any
            time to find out how we can work together to bring a Fresh Eyes
            experience to your learners <a href="mailto:fresheyes.trips@gmail.com">
              fresheyes.trips@gmail.com
            </a>. We'd love to showcase
            where you've been with your learners here in "itineraries".
          </p>
          <h5 ata-aos="fade-up">A Truly Immersive Program</h5>
          <p className="about_paragraph" data-aos="fade-up">
            <b>Applying Ten Score methodology to arrive at Fresh Eyes.</b>
            <br />
            This Google Slides presentation gives a concise overview of how the
            Ten Score CLIL methodology is built into the Fresh Eyes program, and
            points to the major benefits of its interdisciplinary nature.
          </p>
          <Link
            to="https://docs.google.com/presentation/d/e/2PACX-1vRWZ3WFbJGxIVN7ZhV1-WT0pWQMZ-fEDi3ufOnWtx3Pjd6kRAZymJLoq-R9gEf3cnI3oRJBefuzT1hA/pub?start=true&amp%3Bloop=true&amp%3Bdelayms=3000#slide=id.g2d21d510849_0_117"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open presentation</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>
          <hr className="one_links" />
          <h5 ata-aos="fade-up">Fundraising For A Fresh Eyes School Trip</h5>
          <p className="about_paragraph" data-aos="fade-up">
            This video is a Polish language tutorial on how fundraising events
            were organised for a Fresh Eyes school trip in Akademia Pitagorasa
            public primary school (Baranowo, near Poznan). 
            <br />
            <br />
            <b>
              Within the Fresh Eyes program co-financed by Erasmus+, fundraisers
              served 5 broad purposes:
            </b>
            <br />
            <br />
            <b>
              a) To facilitate pupils in applying their Maths skills in a
              practical manner
            </b>{" "}
            (calculating a profit margin)
            <br />
            <br />
            <b>
              b) To facilitate pupils in applying their Chemistry knowledge in a
              practical manner
            </b>{" "}
            (determining calorie counts &amp; creating nutrition pyramids)
            <br />
            <br />
            <b>
              c) To facilitate pupils in learning/practicing English vocabulary
              connected with Food &amp; Nutrition 
            </b>
            (making menus in English with ingredients and allergens)
            <br />
            <br />
            <b>
              d) To collect pocket money for pupils attending the Fresh Eyes
              school trip to Cyprus 
            </b>
            (click on Learner Itineraries &amp; go to Discovering Cyprus With
            Our Senses)
            <br />
            <br />
            <b>
              e) To aim at a high standard of work ethic and instil a sense
              financial equality 
            </b>
            (the 12.000+ PLN earned from sales was divided equally among all
            pupils who participated in the trip)
            <br />
            <br />
          </p>
          <Link
            to="https://youtu.be/UGkXU3s1LOA?si=YeU3A2Wa9A7FBdE"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open video</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>
          <hr className="one_links" />
          <h5 ata-aos="fade-up">Production Task: Your Job - My Gratitude</h5>
          <p className="about_paragraph" data-aos="fade-up">
            This tutorial comprises a full Production Task that uses Ten Score
            CLIL methodology to deliver the following goals:
            <br />
            <ul>
              <li>
                Familiarising yourselves with the wide array of jobs that you
                come into contact with on a daily basis — directly and
                indirectly. 
                <br />
              </li>
              <li>Finding out about these jobs by doing research.</li>
              <li>
                Showing appreciation for those who do some of these jobs, by
                sharing how their jobs improve the quality of your lives.
              </li>
            </ul>
          </p>
          <Link
            to="https://drive.google.com/file/d/1tkS8qeuvJ-EN9Mf44z-HWk3tkTavrSr_/view?usp=sharing"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open document</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>

          <Button
            className="blog_btn_long"
            floated="left"
            style={{ marginTop: "10px" }}
            animated="fade-right"
            data-aos="fade-up-left"
          >
            <a
              href={fresheeysCareerCharts}
              target="_blank"
              download="Fresh_Eyes_Career_Guidance_charts.pdf"
            >
              <Button.Content visible>Download career charts</Button.Content>
              <Button.Content hidden>
                <Icon name="download"></Icon>
              </Button.Content>
            </a>
          </Button>
          <hr className="two_links" />
          <h5 ata-aos="fade-up">
            Production Task: Wise Guys Map Their Own Path
          </h5>
          <p className="about_paragraph" data-aos="fade-up">
            This tutorial gives an overview of how several subjects were
            integrated into the preparatory workshops, on-site tasks during the
            school trip, and active documentation following the trip.
            <br />
            <br />
            Slides 1-13 cover a section of the workshops carried out prior to
            the trip; while Slides 14-22 cover part of the Production Task
            pupils did while on the trip, and Slides 23-26 show how pupils used
            a more tactile medium to capture both their memories and collected
            data. Slide 27 presents the underlying premise of a Fresh Eyes
            school trip.
          </p>
          <Link
            to="https://drive.google.com/file/d/1LpvyFV9esdhGLd81v6tGxfE3gwLvM7xr/view?usp=sharing"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open presentation</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>

          <Button
            className="blog_btn_long"
            floated="left"
            style={{ marginTop: "10px" }}
            animated="fade-right"
            data-aos="fade-up-left"
          >
            <a
              href={slides}
              target="_blank"
              download="slides summary - Wise Guys Map Their Own Path.pdf"
            >
              <Button.Content visible>Download slides overview</Button.Content>
              <Button.Content hidden>
                <Icon name="download"></Icon>
              </Button.Content>
            </a>
          </Button>
          <hr className="two_links" />
          <h5 ata-aos="fade-up">Overview of Interdisciplinary Workshops</h5>
          <p className="about_paragraph" data-aos="fade-up">
            This compilation gives an overview of interdisciplinary workshops
            that were used to integrate several subjects into the Fresh Eyes
            program for primary schools. For detailed, custom-designed tasks
            that teachers can conveniently use with their own pupils to prepare
            them for deep- learning experiences on school trips, we invite you
            to take a look at: Teacher's Resources - Wise Guys Map Their Own
            Path, and Teacher's Resources - Your Job - My Gratitude.
          </p>
          <Link
            to="https://youtu.be/DlASYkobGJU?si=k6SoyTmWkLHpv-6F"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open video</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>
          <hr className="one_links" />
          <h5 ata-aos="fade-up">Summarising A Fresh Eyes School Trip</h5>
          <p className="about_paragraph" data-aos="fade-up">
            This presentation provides a consolidation overview of the stages
            that learners complete in the Fresh Eyes program. We used this
            approach to remind pupils/students of what they&#39;ve done, and to
            give them feedback on the survey that they fill-in on the last day
            of the trip. This also serves as the foremost platform for learners
            to put forward ideas about future Fresh Eyes school trips they would
            like to see take place. The results shown in this presentation come
            from roughly a third of those who participated in the trip to Cyprus
            at Akademia Pitagorasa public primary school. The 3 classes that
            took part did this summarising activity separately, rather than
            altogether, as it was for the preparatory workshops.
          </p>
          <Link
            to="https://drive.google.com/file/d/13xs9E78R5TnEJxqrpavyqDdna-HraxB4/view?usp=sharing"
            target="_blank"
          >
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>Open presentation</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>

          <Button
            className="blog_btn_long"
            floated="left"
            style={{ marginTop: "10px" }}
            animated="fade-right"
            data-aos="fade-up-left"
          >
            <a
              href={fresh_trip}
              target="_blank"
              download="slides summary - summarising a Fresh Eyes school trip.pdf"
            >
              <Button.Content visible>Download slides overview</Button.Content>
              <Button.Content hidden>
                <Icon name="download"></Icon>
              </Button.Content>
            </a>
          </Button>
          <Link to="/itineraries">
            <Button
              className="blog_btn"
              animated="fade-right"
              style={{ marginTop: "10px" }}
              data-aos="fade-up-right"
            >
              <Button.Content visible>See where we've been</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right"></Icon>
              </Button.Content>
            </Button>
          </Link>
          <hr className="two_links" />

          <h5>
            our contact e-mail:{" "}
            <a href="mailto:fresheyes.trips@gmail.com">
              fresheyes.trips@gmail.com
            </a>
          </h5>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default About;
