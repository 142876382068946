import React, {useEffect}  from "react";
import { Grid } from "semantic-ui-react";
import { PhotoSet } from "./PhotoSet";
import AOS from 'aos';
import "aos/dist/aos.css";
export const Gallery = ({galleries, primary}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (

        <Grid.Row data-aos="fade-left" id="GALLERIES" className="iti_section">
                    <Grid.Column computer={12} mobile={12} tablet={12}>
                        {primary ? <h1>follow our footsteps</h1> : <h1>photo galleries</h1>}
                    </Grid.Column>
                {galleries.map((val)=>(
                    <PhotoSet key={val.id} gallery={val} />
                ))}
                
        </Grid.Row>


    )
}

export default Gallery