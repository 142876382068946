import React, { useEffect, useState } from "react";
import { Divider, Grid, Button } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import Itinerary from "./components/Itinerary";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../../const";

export const Itineraries = () => {
  const [itineraries, setItineraries] = useState([]);
  const [schools, setSchools] = useState([]);
  const [destinations, setDestinations] = useState([{ value: 'All', label: "All" },{ value: true, label: "Local"}, {value: false, label: "International"}]);
  const [sortBy, setSortBy] = useState("asc");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [destinationFilter, setDestinationFilter] = useState("");
  const [schoolFilter, setSchoolFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const sortOptions = [
    { value: "desc", label: "Newest" },
    { value: "asc", label: "Oldest" },
  ];

  const getFilterFields = () => {
    axios
      .get(
        `${BASE_URL}/api/itineraries?fields[0]=School_Name&fields[1]=Destination`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        }
      )
      .then((res) => {
        const schoolOptions = [];
        schoolOptions.push({ value: "", label: "All" });
        const schoolNames = [];
        res.data.data.map((item) => {
          if (schoolNames.includes(item.attributes.School_Name)) {
            //pass
          } else {
            schoolOptions.push({
              value: item.attributes.School_Name,
              label: item.attributes.School_Name,
            });
            schoolNames.push(item.attributes.School_Name);
          }
        });
        setSchools(schoolOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filterItineraries = () => {
    let url = `${BASE_URL}/api/itineraries?filters[local][$eq]=${destinationFilter}&filters[School_Name][$contains]=${schoolFilter}${dateFrom !== null ? `&filters[Date_From][$gte]=${dateFrom.toISOString()}` : ''}${dateTo !== null ? `&filters[Date_To][$lte]=${dateTo.toISOString()}` : ''}&fields[0]=Title&fields[1]=Origin&fields[2]=Destination&fields[3]=Date_From&fields[4]=Date_To&fields[5]=TrailerLink&fields[6]=Slug&fields[7]=Large_Image&populate=Large_Image&sort[0]=Date_From:${sortBy}`
    if(destinationFilter === 'All'){
      url = `${BASE_URL}/api/itineraries?filters[School_Name][$contains]=${schoolFilter}${dateFrom !== null ? `&filters[Date_From][$gte]=${dateFrom.toISOString()}` : ''}${dateTo !== null ? `&filters[Date_To][$lte]=${dateTo.toISOString()}` : ''}&fields[0]=Title&fields[1]=Origin&fields[2]=Destination&fields[3]=Date_From&fields[4]=Date_To&fields[5]=TrailerLink&fields[6]=Slug&fields[7]=Large_Image&populate=Large_Image&sort[0]=Date_From:${sortBy}`
    }
    axios
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        }
      )
      .then((res) => {
        setItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getItineraries = () => {
    axios
      .get(
        `${BASE_URL}/api/itineraries?fields[0]=Title&fields[1]=Origin&fields[2]=Destination&fields[3]=Date_From&fields[4]=Date_To&fields[5]=TrailerLink&fields[6]=Slug&fields[7]=Large_Image&populate=Large_Image`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        }
      )
      .then((res) => {
        setItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getItineraries();
    getFilterFields();
  }, []);

  return (
    <Grid
      style={{ marginTop: "100px", minHeight: "80vh" }}
      centered
      stackable
      columns="equal"
    >
      <Grid.Row>
        <Grid.Column width={3}>
          <span>Select School</span>
          <Select
            defaultValue={{ label: "All", value: "" }}
            options={schools}
            onChange={(e) => {
              setSchoolFilter(e.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <span>Destination</span>
          <Select
            options={destinations}
            defaultValue={{ label: "All", value: "" }}
            onChange={(e) => {
              setDestinationFilter(e.value);
            }}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <span>Date From</span>
          <SemanticDatepicker onChange={(e, data) => {setDateFrom(data.value)}} />
        </Grid.Column>
        <Grid.Column width={2}>
          <span>Date To</span>
          <SemanticDatepicker onChange={(e, data) => setDateTo(data.value)} />
        </Grid.Column>
        <Grid.Column width={2}>
          <span>Sort By</span>
          <Select
            options={sortOptions}
            defaultValue={sortBy}
            onChange={(e) => {setSortBy(e.value)}}
          />
        </Grid.Column>

        <Grid.Column width={2}>
          <Button
            style={{ marginTop: 20, background: "#2E7EB8", color: "white" }}
            onClick={filterItineraries}
          >
            Filter
          </Button>
        </Grid.Column>
        <Divider clearing style={{ width: "100%" }} />
      </Grid.Row>

      {itineraries.map((val, index) => {
        return (
          <Itinerary itinerary={val.attributes} key={val.id} index={index} />
        );
      })}
    </Grid>
  );
};

export default Itineraries;
