import React, {useState} from "react";
import { Grid } from "semantic-ui-react";
import ImageGallery from "react-image-gallery";
import { BASE_URL } from "../../../const";
export const PhotoSet = ({ gallery }) => {
  let images = []
  if(gallery.Photos.data !== null){
    images = gallery.Photos.data.map((val) => {
      if (val.attributes.formats.hasOwnProperty("large")) {
        return {
          original: `${BASE_URL}` + val.attributes.formats.large.url,
          description: val.attributes.caption,
        };
      } else {
        return {
          original: `${BASE_URL}` + val.attributes.url,
          description: val.attributes.caption,
        };
      }
      
    })
    ;}

  return (
    <Grid.Column
      computer={12}
      tablet={14}
      mobile={14}
      textAlign="center"
      style={{ marginTop: "15px" }}
    >
      <h3 style={{ marginTop: "5px" }}>{gallery.Title}</h3>
      <p>{gallery.Gallery_Description}</p>
      <ImageGallery
        style={{ maxWidth: "706px", maxHeight: "530px" }}
        showFullscreenButton={false}
        items={images}
        showPlayButton={false}
        showBullets={true}
        originalTitle={true}
      />
    </Grid.Column>
  );
};

export default PhotoSet;
