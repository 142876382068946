import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import Story from "./Story";
import AOS from 'aos';
import "aos/dist/aos.css";

export const WordTales = ({ stories, primary }) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (

        <Grid.Row data-aos="flip-up" id="WORD_TALES" className="iti_section">

            <Grid.Column computer={12} mobile={12} tablet={12} textAlign='center' style={{ minWidth: '200px' }}>
                {primary ? <h1>our flash fiction</h1> : <h1>tales <br />to tell</h1>}
                
            </Grid.Column>

            <Grid.Column computer={12} mobile={16} tablet={16} style={{marginTop:'15px'}}>
                {stories.map((val) => {
                    return <Story key={val.id} story={val} />
                })}

            </Grid.Column>
        </Grid.Row>

    )
}

export default WordTales