import React, {useEffect} from "react";
import { Grid } from 'semantic-ui-react'
import PhotoTextSection from "./PhotoTextSection";
import AOS from 'aos';
import "aos/dist/aos.css";
export const LiveLearn = ({livelearns}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (    
        <Grid.Row data-aos="fade-right" id="LIVE_LEARN" className="iti_section">
                <Grid.Row> 
                    <Grid.Column computer={16} mobile={12} textAlign='center'>
                        <h1>live & learn</h1>
                    </Grid.Column>
                    <Grid.Column computer={16} mobile={12} textAlign='center' className='Title_underline'>
                        <h2>our experiences</h2>
                    </Grid.Column>
                </Grid.Row>
            <Grid.Column computer={14} mobile={16}>
                {livelearns.map((val)=>{
                    return <PhotoTextSection key={val.id} section={val} />
                })}
                
            </Grid.Column>
        </Grid.Row>

    )
}

export default LiveLearn;