import React from "react";
import { Grid } from "semantic-ui-react";
import down_arrow from '../img/down_arrow.svg'
export const DownArrow = () => {

    return (

        <Grid.Row>
            <Grid.Column only='computer' computer={12} textAlign='right'>
                <img src={down_arrow} loading="lazy"/>
            </Grid.Column>
            <Grid.Column only='tablet mobile' tablet={12} mobile={12} textAlign='center'>
                <img src={down_arrow} style={{width:'37px'}} loading="lazy"/>
            </Grid.Column>
        </Grid.Row>

    )

}


export default DownArrow