import React, { useEffect, useState } from "react";
import {Grid} from 'semantic-ui-react'
import { BASE_URL } from "../../../const";
export const PhotoTextSection = ({section}) => {
    const [file, setFile] = useState('')
    useEffect(()=>{
        if(section.Photo.data !== null){
            setFile(section.Photo.data.attributes.url)
        }},[section])

    return (
        <Grid centered>
        <Grid.Row>
            <Grid.Column computer={14} mobile={14} tablet={12}>
                {section.Photo !== '' ? <div className='fullColumnPhoto' style={{backgroundImage: `url("${BASE_URL}${file}")`, objectFit:'cover', backgroundSize:'cover'}}></div>: null}
                
            </Grid.Column>
            <Grid.Column computer={14} mobile={14} tablet={12} style={{marginTop:'10px'}}>
                {section.Title !== null ? <h3> {section.Title}</h3> : null}
                {section.Paragraph !== null ? <p>
                   {section.Paragraph}
                </p> : null}
                
            </Grid.Column>

        </Grid.Row>
        </Grid>
    )
}

export default PhotoTextSection