import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import Videos from "./Videos";
import AOS from 'aos';
import "aos/dist/aos.css";

export const VideoTales = ({vlogs}) => {
    useEffect(() => {
        AOS.init({duration: 800, disable: 'mobile'});
      }, []);
    return (
        <Grid.Row data-aos="fade-right" id="VLOGS" className="iti_section">


                <Grid.Column computer={12} mobile={12} tablet={12} textAlign='center'>
                    <h1>vlogs</h1>
                </Grid.Column>

                <Videos vlogs={vlogs} />

           
        </Grid.Row>
    )
}

export default VideoTales